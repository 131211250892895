var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('StageStatusPanel',{staticClass:"mb-xl"}),_c('section',{staticClass:"mb-l"},[(_vm.profileList.length)?_c('div',[_c('div',{staticClass:"row cards-list"},_vm._l((_vm.profileList),function(profile){return _c('div',{key:profile.id,staticClass:"col-xs-12 cards-list__item"},[_c('div',{staticClass:"stage-profile cards-list__card"},[_c('div',{staticClass:"stage-profile__icon"},[(profile.icon)?_c('BaseImage',{attrs:{"src":profile.icon}}):_c('BaseImage',{attrs:{"src":require("@/assets/images/profile_placeholder.svg")}})],1),_c('div',{staticClass:"stage-profile__content"},[_c('div',{staticClass:"stage-profile__type text-size-sm"},[_vm._v(" "+_vm._s(profile.profileType)+" ")]),_c('div',{staticClass:"stage-profile__title text-size-h4 text-bold"},[_vm._v(" "+_vm._s(profile.title)+" ")]),_c('div',{staticClass:"stage-profile__row"},[_c('div',{staticClass:"stage-profile__col"},[(!profile.hideTeamInfo)?_c('div',[(!profile.team)?_c('p',[_vm._v(" Вам нужно собрать команду: создать свою или присоединиться к существующей. Без команды вы не сможете решать командные задачи. ")]):_c('div',[(_vm.teams[profile.id])?_c('div',[_vm._v(" Команда: "+_vm._s(_vm.teams[profile.id].title)+" ")]):_c('div',[_vm._v(_vm._s(profile.team))])]),_c('TeamBuildingStageText',{staticClass:"mt-xs",attrs:{"stage":profile.buildingStage}})],1):_vm._e()]),_c('div',{staticClass:"stage-profile__col stage-profile__col--controls"},[(
                      !profile.hideTeamInfo &&
                      !profile.team &&
                      profile.allowTeamBuilding
                    )?[_c('BaseButton',{staticClass:"mb-xs",attrs:{"theme":"primary-border"},on:{"click":function($event){return _vm.openEditTeamModal(profile.id)}}},[_vm._v("Создать команду")]),_c('BaseButton',{attrs:{"theme":"primary-border"},on:{"click":function($event){return _vm.openFindTeamModal(profile.id)}}},[_vm._v("Найти команду")])]:_c('BaseButton',{staticClass:"mb-xs",attrs:{"theme":"primary-border","tag":"router-link","to":{
                      name: `user-profile-stage-${_vm.stageNumber}`,
                      params: {
                        profile_id: profile.id,
                      },
                    }}},[_vm._v("Подробнее")])],2)])])])])}),0)]):_c('p',[_vm._v("Нет профилей и спецпроектов командного этапа")])]),(_vm.educationBlocks.length && _vm.profileList.length)?_c('EducationBlocks',{staticClass:"mb-l mt-l",attrs:{"blocks":_vm.educationBlocks}}):_vm._e(),(_vm.profileList.length)?_c('section',[_c('h4',{staticClass:"text-size-h4 text-bold color-secondary mb-s"},[_vm._v("Задания")]),(_vm.allowedActivities.length)?_c('div',_vm._l((_vm.allowedActivities),function(item){return _c('ActivityCard',{key:item.id,staticClass:"mb-xs",attrs:{"activity":item,"score":_vm.activityScores ? _vm.activityScores[item.id] : null}})}),1):_c('div',[_vm._v("Расписание уточняется, задания появятся позже.")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }